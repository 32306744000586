<template>
  <div class="wa__modeling__register h-100">
    <header>
      <!-- <div class="d-flex justify-center align-center ml-0 ml-md-9">
        <brand-name />
      </div> -->
      <v-row>
        <v-col cols="6" sm="7" class="text-sm-right text-left"
          ><div class="pa-2 pt-5"><BrandName /></div
        ></v-col>
        <v-col cols="6" sm="5" class="text-right"
          ><div class="pa-2 pt-5">
            <v-chip label text-color="black" @click="changeLang">
              {{ $_getLocale() === "jp" ? "EN" : "JP" }}</v-chip
            >
          </div>
        </v-col>
      </v-row>
      <!-- <div class="d-flex justify-space-between pa-2 pt-5">


      </div> -->
    </header>
    <v-container class="pb-0">
      <div class="mt-10 mt-md-16 pt-md-16">
        <v-row class="py-10 h-100 ma-0 ma-mmd-auto">
          <v-col cols="12" xl="6" class="px-md-10 px-0 pt-4">
            <RegisterSection :form="form" />
          </v-col>
          <v-col cols="1" class="text-center pl-0 d-none d-lg-block">
            <v-divider vertical class="mr-1"></v-divider>
          </v-col>
          <v-col cols="5" class="d-none d-lg-block">
            <div class="wa__f__m__eb auth__text">
              <div class="text-uppercase">your modelling journey starts here</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import BrandName from "@/components/Global/Header/BrandName";
import { UserStorage } from "@/utils/storage";
import RegisterSection from "@/components/Global/Section/RegisterSection";
import {password} from "@/mixins/validate";
export default {
  components: {
    BrandName,
    RegisterSection,
  },
  data: () => ({
    form: {},
    userType: UserStorage.get() ? UserStorage.get().type : null,
  }),
  computed: {
    password,
  },
  methods: {
    userRouteManagement() {
      if (this.userType === "client") {
        this.$router.push({
          name: "modelRegistration",
          params: { locale: this.$_getlocaleParam() },
        });
      } else if (this.userType === "model" || this.userType === "manager") {
        this.$router.push({
          name: "dashboard",
          params: { locale: this.$_getlocaleParam() },
        });
      }
    },
    changeLang() {
      this.$_changeLocalePage();
      this.form = {};
    },
  },
  created() {
    this.userRouteManagement();
  },
};
</script>
<style>
@media screen and (min-width: 1904px) {
  .container {
    max-width: 1585px;
  }
}
.wa__modeling__register {
  background-image: linear-gradient(
    to left,
    var(--color-bg-smoky),
    var(--color-bg-light-gray)
  ) !important;
}
.welcome__text {
  font-family: "montserrat-light", sans-serif;
  font-size: 20px;
}
.auth__base {
  border: 1px solid var(--color-white) !important;
  border-radius: 5px;
  height: 500px;
}
.auth__title {
  font-size: 30px;
}
.auth__subtitle {
  line-height: 4px;
  color: var(--color-gray);
}
.prefrred__lang {
  font-family: "montserrat-light", sans-serif;
  font-size: 14px;
  color: #acacac;
  text-align: left;
  letter-spacing: 1px;
}
.btn-language {
  font-family: "montserrat-regular";
  color: #c3c3c3 !important;
  border: 1px solid #c3c3c3 !important;
  font-weight: bold;
  border-radius: 5px !important;
}
.active {
  border: 1px solid var(--color-black) !important;
  font-family: "montserrat" !important;
}
.auth__text {
  position: relative;
  height: 100%;
  display: table;
}
.auth__text div {
  font-family: "Montserrat-ExtraLight", sans-serif;
  color: var(--color-black);
  letter-spacing: 6px;
  font-size: 80px;
  line-height: 66px;
  width: min-content;
  vertical-align: middle;
  display: table-cell;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -50%); */
}
/* verify code */
.btn-register,
.btn-send {
  font-size: 20px !important;
  letter-spacing: 3px !important;
  float: right;
  height: 40px !important;
}
.btn-send {
  width: -webkit-fill-available !important;
}
.btn-return {
  font-size: 15px !important;
  letter-spacing: 3px !important;
}
</style>
